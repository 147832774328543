@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap);
*,
html,
body {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
}

.app {
  width: 100dvw;
}

h4, h3, h2 {
  text-align: center;
}
.floating-contact-form {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .contact-icon {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .contact-icon:hover {
    transform: scale(1.1);
    color: #333;
    filter: drop-shadow(1px 1px 20px #007bff);
  }

  .contact-icon svg {
    width: 30px;
    height: 30px;
  }

  .contact-form {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 20px #007bff;
    width: 300px;
  }

  .lets-chat {
    margin-bottom: 8px;
    font-weight: bolder;
    text-align: center;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact-form button[type="submit"] {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .thank-you-message {
    text-align: center;
    font-size: 18px;
    color: #28a745;
  }
  
  /* Media Queries */
  
  /* Mobile devices */
  @media screen and (max-width: 480px) {
    .floating-contact-form {
      bottom: 10px;
      right: 10px;
    }
  
    .contact-icon {
      width: 50px;
      height: 50px;
    }
  
    .contact-icon svg {
      width: 25px;
      height: 25px;
    }
  
    .contact-form {
      width: 250px;
    }
  }
  
  /* Tablets */
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .floating-contact-form {
      bottom: 15px;
      right: 15px;
    }
  
    .contact-form {
      width: 280px;
    }
  }
  
  /* Laptops and larger screens */
  @media screen and (min-width: 769px) {
    .floating-contact-form {
      bottom: 20px;
      right: 20px;
    }
  
    .contact-form {
      width: 300px;
    }
  }
